import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import PageTransition from 'gatsby-plugin-page-transitions';
import { AppearText } from '../components/common/appear';
import { Helmet } from 'react-helmet';
import RequestConsultationMobileButton from '../components/requestconsultationmobileButton';
import HomepageTop from '../components/homeBackground';
import HomepageFooter from '../components/homeFooter';
import Image from '../components/ParallaxImage';

import SeoImage from '../assets/images/seo/home.jpg';

const Page = styled.div`
  min-height: 100vh;
  margin: 0 auto;
  width: 100%;
  max-width: 1620px;
  @media (max-width: 1600px) {
    max-width: 1241px;
  }
  
  @media (max-width: 1200px) {
    min-height: auto;
  }
`;

const SecondSect = styled.div`
  width: 100%;
  height: auto;
  margin-top: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  background-color: #FFF;
  position: relative;
  z-index: 10;
  @media (min-width: 1200px) and (max-height: 820px) {
    margin-top: 920px;
  }
`;

const ThirdSect = styled.div`
  width: 100%;
  height: auto;
  padding: 120px;
  @media (max-width: 1200px) {
    padding: 57px;
    min-height: auto;
  }
  @media (max-width: 600px) {
    padding: 57px 15px;
  }
`;

const Col = styled.div`
  width: 100%;
  padding: 120px;
  height: auto;
  padding-right: 80px;
  padding-left: ${props => props.paddingLeft && '0 !important;'};
  @media (max-width: 1200px) {
    padding: 61px 57px;
  }
  @media (max-width: 600px) {
    padding: 31px 15px;
    padding-left: ${props => props.paddingLeft && '15px !important;'};
  }
  h3 {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 58px;

    color: #00A8E3;

    @media (max-width: 1200px) {
      font-size: 32px;
      line-height: 38px;
    }
  }
  p {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 135%;
    /* or 35px */
    margin-top: 70px;

    color: #0E0E0E;
    @media (max-width: 1200px) {
      font-size: 20px;
      line-height: 27px;
      margin-top: 30px;
    }
  }
`;

const WrapperImage = styled.div`
  margin-top: 0px;
`;

const IndexPage = ({ data, pageContext }) => (
  <PageTransition>
    <>
      <Helmet>
        <title>Blue Ocean Insurance Broker</title>
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="default"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, user-scalable=no"
        />
        <meta
          name="description"
          content="A brand-new concept of Insurance, a wide new world of possibility for Personal and Commercial needs"
        />
        <meta
          property="og:type"
          content="website"
        />
        <meta
          property="og:url"
          content="https://boinsurancebroker.com/en/"
        />
        
        <meta property="og:title" content="Blue Ocean Insurance Broker" />
        <meta property="og:description" content="A brand-new concept of Insurance, a wide new world of possibility for Personal and Commercial needs" />
        <meta property="og:image" content={SeoImage} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://boinsurancebroker.com/en/" />
        <meta property="twitter:title" content="Blue Ocean Insurance Broker" />
        <meta property="twitter:description" content="A brand-new concept of Insurance, a wide new world of possibility for Personal and Commercial needs" />
        <meta property="twitter:image" content={SeoImage} />
      </Helmet>
      <Page>
        <HomepageTop
          webGlEnabled
          langKey={pageContext && pageContext.langKey}
          title={data.markdownRemark.frontmatter.slider[0].title}
          paragraph={
            data.markdownRemark.frontmatter.slider_paragraph_kh ? 
              data.markdownRemark.frontmatter.slider_paragraph_kh :
              data.markdownRemark.frontmatter.slider_paragraph
          }
          renderRequest={
            () => {
              return <RequestConsultationMobileButton
                normalText={
                  pageContext && pageContext.langKey === 'kh' ? "ទំនាក់ទំនងពួកយើង" : "Request<br/>Consultation"}
                boldText={pageContext && pageContext.langKey === 'kh' ? "ឥឡូវ​នេះ" : "Now"}
              />
            }
          }
        />
        <SecondSect>
          <Col>
            <AppearText>
              <h3>{data.markdownRemark.frontmatter.second_title_kh}</h3>
            </AppearText>
            <AppearText>
              <p>
                {data.markdownRemark.frontmatter.second_paragraph_kh}
              </p>
            </AppearText>
          </Col>
          <Col paddingLeft>
            <WrapperImage>
              <Image />
            </WrapperImage>
          </Col>
        </SecondSect> 
      </Page>
      <ThirdSect style={{
        width: '100%',
      }}
      >
        <HomepageFooter
          langKey={pageContext && pageContext.langKey}
          centeredText={data.markdownRemark.frontmatter.footer_paragraph_kh}
        />
      </ThirdSect>
    </>
  </PageTransition>
);

export default function myIndex(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
            frontmatter {
              slider {
                title
                title_kh
              }
              title
              second_title_kh
              second_paragraph_kh
              footer_paragraph_kh
              slider_paragraph
              slider_paragraph_kh
            }
          }
        }
      `}
      render={data => <IndexPage data={data} {...props} />}
    />
  )
}